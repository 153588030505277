import logo from "../../images/logo.png";
export default function Header() {
  return (
    <header className="header">
      <a href="" className="logo">
        <img src={logo} alt="logo" />
      </a>
      <div className="humbarger">
        <span />
        <span />
        <span />
      </div>
    </header>
  );
}
