import { useRef, useEffect, useState, useContext } from "react";
import LanguageContext from "../Context/language-context";
import sanityClient from "../../client";
import { PortableText } from "@portabletext/react";
import Slider from "react-slick";
import rarrow from "../../images/arrow-right-white.png";

import shape from "../../images/portfolio-shape.png";

export default function Services(props) {
  const ctx = useContext(LanguageContext);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const next = useRef(null);
  const [slideData, setSlideData] = useState(0);
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    if (slider1.current && slider2.current) {
      slider1.current.slickGoTo(0);
      slider2.current.slickGoTo(0);
    }
  }, []);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type== "projects" && language == $language]{
      type,
      title,
      description,
      nextslide,
      image{
        asset->{
          _id,
          url,
        }
      },
      _translations[] {
        value->{
          type,
          title,
          description,
          nextslide,
          image{
            asset->{
              _id,
              url,
            }
          }
        }
     }
    }`,
        { language: ctx.languageData }
      )
      .then((data) => setProjectData(data));
  }, []);

  const handleSlideChange = (oldIndex, newIndex) => {
    if (slider1.current && slider2.current) {
      slider1.current.slickGoTo(newIndex);
      slider2.current.slickGoTo(newIndex);
    }
  };

  const handleAfterChange = (current) => {
    setSlideData(current);
  };

  return (
    <section
      className="section portfolio-section"
      // style={{ background: ` url(${props.img}) no-repeat center center/cover` }}
    >
      <div className="overlay" />
      <img
        src={shape}
        alt="shape"
        className="shape"
        data-enllax-ratio="0.5"
        data-enllax-type="foreground"
      />

      <h2 className="section-title">Services</h2>
      <div className="container">
        <div className="portfolio-wrapper">
          <Slider
            ref={slider1}
            draggable={true}
            prevArrow={<></>}
            nextArrow={<></>}
            fade={true}
            speed={2000}
            beforeChange={handleSlideChange}
            afterChange={handleAfterChange}
            className="portfolio-img"
          >
            {projectData &&
              projectData.map((item, index) => (
                <div className="img-area" key={index}>
                  <img src={item.image.asset.url} alt="portfolio img" />
                </div>
              ))}
          </Slider>
          <Slider
            ref={slider2}
            beforeChange={handleSlideChange}
            afterChange={handleAfterChange}
            speed={2000}
            nextArrow={
              <button
                type="button"
                className="slick-next slick-arrow"
                style={{}}
              >
                <img src={rarrow} alt="arrow" />
              </button>
            }
            prevArrow={
              <button
                type="button"
                className="slick-prev slick-arrow"
                style={{}}
              >
                <img src={rarrow} alt="arrow" />
              </button>
            }
            className="portfolio-content-wrapper"
          >
            {projectData &&
              projectData.map((item, index) => (
                <div
                  className="portfolio-content"
                >
                  <span className="portfolio-type">{item.type}</span>
                  <h2>{item.title}</h2>
                  <PortableText value={item.description} />
                </div>
              ))}
          </Slider>

          <span className="portfolio-paging-status">{slideData + 1} / 5</span>
        </div>
      </div>
    </section>
  );
}
