import { useEffect, useState, useRef, useContext } from "react";
import { PortableText } from "@portabletext/react";
import sanityClient from "../../client";
import Slider from "react-slick";
import rarrow from "../../images/arrow-right-white.png";
import LanguageContext from "../Context/language-context";

export default function WhyUs(props) {
  const slider = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [serviceData, setServiceData] = useState(null);
  const ctx = useContext(LanguageContext);
  useEffect(() => {
    if (slider.current) {
      slider.current.slickGoTo(0);
    }
  }, []);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type== "services" && language == $language]{
      title,
      description,
      image{
        asset->{
          _id,
          url,
        }
      },
      _translations[] {
        value->{
          title,
          description,
          image{
            asset->{
              _id,
              url,
            }
          }
        }
     }
    }`,
        {
          language: ctx.languageData,
        }
      )
      .then((data) => setServiceData(data));
  }, [ctx.languageData]);

  const handleAfterChange = (current) => {
    setCurrentSlide(current);
  };
  const sliderSettings = {
    draggable: true,
    fade: true,
    nextArrow: (
      <button type="button" className="slick-next slick-arrow" style={{}}>
        <img src={rarrow} alt="arrow" />
      </button>
    ),
    prevArrow: (
      <button type="button" className="slick-prev slick-arrow" style={{}}>
        <img src={rarrow} alt="arrow" />
      </button>
    ),
    speed: 1300,
    className: "service-wrapper",
    id: "home2-service",
    afterChange: handleAfterChange,
  };

  return (
    <section
      className="section service-section pos-relative"
      // style={{
      //   background: `url(${props.img}) no-repeat left center/cover`,
      // }}
    >
      <h2 className="section-title">Why Us</h2>
      <div className="container">
        <Slider ref={slider} {...sliderSettings}>
          {serviceData &&
            serviceData.map((slide, index) => (
              <div
                key={index}
                className={`single-service pos-relative ${
                  currentSlide === index ? "animate" : ""
                }`}
              >
                <div className="service-text">
                  <h2>{slide.title}</h2>
                  <PortableText value={slide.description} />
                </div>
                <img
                  src={slide.image.asset.url}
                  alt="service-image"
                  className="service-img"
                />
              </div>
            ))}
        </Slider>

        <span className="service2-paging-status" />
      </div>
    </section>
  );
}
