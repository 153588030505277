import "./css/fullpage.min.css";
import "./css/global-style.css";
import "./css/style-2.css";
import "./css/responsive-2.css";
import "./css/slick.css";
// import "./css/responsive.css"
import Header from "./components/UI/Header";
import Intro from "./components/UI/Intro";
import About from "./components/UI/About";
import WhyUs from "./components/UI/WhyUs";
import Services from "./components/UI/Services";
import Footer from "./components/UI/Footer";
import NavBar from "./components/UI/Navbar";
import EnvironmentalEffort from "./components/UI/Environmental";
import Gallery from "./components/UI/Gallery";

function App() {
  return (
    <>
      <Header />
      <NavBar />
      <div id="fullpage" className="fp-scroll">
        <>
          <Intro />
          <About />
          <Services />
          <WhyUs />
          <EnvironmentalEffort />
          <Gallery />
          <Footer />
        </>
      </div>
    </>
  );
}

export default App;
