import { useState, useEffect, useContext } from "react";
import sanityClient from "../../client";
import LanguageContext from "../Context/language-context";
import { PortableText } from "@portabletext/react";
export default function EnvironmentalEffort() {
  const [skillsData, setSkillsData] = useState(null);
  const ctx = useContext(LanguageContext);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type== "skills" && language == $language]{
      skill,
      description,
      _translations[] {
        value->{
          skill,
      description
        }
     }
    }`,
        { language: ctx.languageData }
      )
      .then((data) => setSkillsData(data));
  }, []);
  return (
    <section className="section skill-section">
      <div className="container">
        <h2 className="section-title">Environmental Effort</h2>
        <div className="skill-wrapper" id="me-working-skill">
          <span className="vertical-line" />
          {skillsData &&
            skillsData.map((item, index) => (
              <div className="single-skill animate-me" key={index}>
                <span className="circle" />
                <span className="rectengle" />
                <h3>
                  <span className="title">{item.skill}</span>
                </h3>
                <PortableText
                  className="description"
                  value={item.description}
                />
                {/* <p className="description">{item.description}</p> */}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
