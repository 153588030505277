import shape from "../../images/about-shape.png";
import dot from "../../images/dot-texture.png";
import sanityClient from "../../client";
import { useState, useEffect, useContext } from "react";
import LanguageContext from "../Context/language-context";

export default function About(props) {
  const [aboutData, setAboutData] = useState(null);
  const ctx = useContext(LanguageContext);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type== "about" && language == $language]{
      text,
      description,
      image{
        asset->{
          _id,
          url,
        }
      },
      image2{
        asset->{
          _id,
          url,
        }
      },
      _translations[] {
        value->{
          text,
      description,
      image{
        asset->{
          _id,
          url,
        }
      },
      image2{
        asset->{
          _id,
          url,
        }
      }
        }
     }
    }`,
        { language: ctx.languageData }
      )
      .then((data) => setAboutData(data[0]));
  }, []);
  return (
    <section
      className="section about-section pos-relative"
      // style={{ background: `url(${props.img}) no-repeat center top/cover` }}
    >
      <div className="overlay" />
      <div className="about-shape">
        <img src={shape} alt="shape" className="shape" />
        <span className="circle1" />
      </div>
      <div className="container">
        <img src={dot} alt="pattern img" className="dot-pattern" />
        {aboutData ? (
          <>
            <span
              className="about-img1 animate-me"
              style={{
                background: `url(${aboutData.image.asset.url}) no-repeat top left/cover`,
              }}
            />
            <div className="about-content">
              <h2 className="animate-me">{aboutData.text}</h2>
              <h5 className="animate-me">{aboutData.description}</h5>
            </div>
            <span
              className="about-img2 animate-me"
              style={{
                background: `url(${aboutData.image2.asset.url}) no-repeat center right/cover`,
              }}
            />
          </>
        ) : (
          <>
            <span className="about-img1 animate-me" />
            <div className="about-content">
              <h2 className="animate-me">
                Meet my startup design agency team UITrendy. Currently i am
                working at UITrendy as Wed Developer.{" "}
              </h2>
              <h5 className="animate-me">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 B making it over 2000 years old. Richard McClintock, a Latin
                professor at Hampden-Sydney Colle Virginia, looked up one of the
                mo obscure Latin words, consectetured.
              </h5>
            </div>
            <span className="about-img2 animate-me" />
          </>
        )}
      </div>
    </section>
  );
}
