import dot from "../../images/dot-texture.png";
import darrow from "../../images/scroll-down-arrow.png";
import rarrow from "../../images/arrow-right-white.png";
import LanguageContext from "../Context/language-context";
import { PortableText } from "@portabletext/react";
import sanityClient from "../../client";
import { useEffect, useState, useContext } from "react";
import Socials from "./Socials";

export default function Intro(props) {
  const [introData, setIntroData] = useState(null);
  const ctx = useContext(LanguageContext);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type== "intro" && language == $language]{
      name,
      bio,
      hire,
      image{
        asset->{
          _id,
          url,
        }
      },
      logo{
        asset->{
          _id,
          url,
        }
      },
      _translations[] {
        value->{
          name,
      bio,
      hire,
      image{
        asset->{
          _id,
          url,
        }
      },
      logo{
        asset->{
          _id,
          url,
        }
      }
        }
     }
    }`,
        { language: ctx.languageData }
      )
      .then((data) => setIntroData(data[0]));
  }, []);
  return (
    <section
      className="section hero-section pos-relative"
      // style={{ background: `#16171b url(${props.img}) no-repeat` }}
    >
      {introData && (
        <>
          <span className="vertical-logo">
            <img src={introData.logo.asset.url} alt="vertical-logo" />
          </span>
          <div className="hero-image-wrapper">
            <span
              className="hero-image animate-me animated"
              style={{
                background: `url(${introData.image.asset.url}) no-repeat center right/cover`,
              }}
            />
            <img src={dot} alt="texture" className="dot-texture animate-me" />
          </div>
          <a href="#aboutMe" className="scroll-down animate-me">
            scroll down <img src={darrow} alt="arrow" />
          </a>

          <Socials />
          <div className="container">
            <div className="content-wrapper">
              <div className="hero-content">
                <h1 className="animate-me mgr">{introData.name}</h1>
                {/* <PortableText className="animate-me" value={introData.bio} /> */}

                <h2 className="animate-me animated mgr-sub">{introData.bio}</h2>
                <a
                  href="#contacts"
                  className="hire-me link-default animate-me animated"
                >
                  {introData.hire}
                  <span>
                    <img src={rarrow} alt="arrow" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
