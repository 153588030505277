import Socials from "./Socials";
import horizontallogo from "../../images/logo.png";

export default function NavBar() {
  return (
    <>
      <div className="menu-wrapper">
        <ul className="main-menu" id="main-menu">
          <li>
            <a href="#aboutMe" data-text="About">
              About
            </a>
          </li>
          <li>
            <a href="#services" data-text="Services">
              Services
            </a>
          </li>
          <li>
            <a href="#whyUs" data-text="Why Us">
              Why Us
            </a>
          </li>
          <li>
            <a href="#environmentaleffort" data-text="Environmental Effort">
              Environmental Effort
            </a>
          </li>
          <li>
            <a href="#gallery" data-text="Gallery">
              Gallery
            </a>
          </li>
          <li>
            <a href="#contacts" data-text="Contact">
              Contact
            </a>
          </li>
        </ul>
        <div className="quick-link">
          <div className="email">
            <span>Want to talk?</span>
            <a href="#contacts">
              <span className="__cf_email__">jewelrymgr@gmail.com</span>
            </a>
          </div>
          <div className="follow-me">
            <span>Follow me :</span>
            <Socials />
          </div>
        </div>
        <img src={horizontallogo} alt="image" className="horizontal-img" />
      </div>
    </>
  );
}
