import { useState, useEffect, useContext } from "react";
import { PortableText } from "@portabletext/react";
import LanguageContext from "../Context/language-context";
import sanityClient from "../../client";
import useInput from "../../hooks/use-input";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => {
  return value.trim().length > 5 && value.includes("@");
};
export default function Footer(props) {
  const [contactData, setContactData] = useState(null);
  const {
    value: nameValue,
    isValid: nameIsValid,
    hasError: nameError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetname,
  } = useInput(isNotEmpty);
  const {
    value: messageValue,
    isValid: messageIsValid,
    hasError: messageError,
    valueChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    reset: resetmessage,
  } = useInput(isNotEmpty);
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetemail,
  } = useInput(isEmail);

  let formIsValid = false;
  if (nameIsValid && messageIsValid && emailIsValid) {
    formIsValid = true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nameIsValid && messageIsValid && emailIsValid) {
      const formData = new FormData();
      formData.append("name", nameValue);
      formData.append("email", emailValue);
      formData.append("message", messageValue);

      // Send form data to the server
      try {
        const response = await fetch("contact.php", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status === "success") {
            MySwal.fire({
              icon: "success",
              title: "Success",
              text: data.message,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }
        } else {
          // Show error message to the user
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Form submission failed",
          });
        }
      } catch (error) {
        console.error("Form submission failed", error);
      }
      resetemail();
      resetname();
      resetmessage();
    } else {
      return;
    }
    // Perform form validation if needed

    // Send form data to the server
  };

  const ctx = useContext(LanguageContext);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type== "contact" && language == $language]{
          address,
          email,
          number,
          facebook,
          instagram,
          _translations[] {
            value->{
              address,
              email,
              number,
              facebook,
              instagram,
            }
         }
        }`,
        { language: ctx.languageData }
      )
      .then((data) => setContactData(data[0]));
  }, [ctx.LanguageContext]);

  return contactData ? (
    <section className="section footer-area">
      <div className="contact-section">
        <div className="container">
          <div className="section-title">
            <h2>contacts</h2>
          </div>
          <div className="content-wrapper">
            <div className="address-area">
              <address>
                <span className="label address-label animate-me">
                  address :
                </span>
                <p className=" address animate-me">
                  <PortableText
                    className="address animate-me"
                    value={contactData.address}
                  />
                </p>
                <br />
                <span className="label address-label animate-me">
                  whatsapp:
                </span>
                <p className=" address animate-me">
                  <PortableText value={contactData.number} />
                </p>

                <span className="label email-label animate-me">E-mail :</span>
                <a href="#" className="email animate-me">
                  <span className="__cf_email__">{contactData.email}</span>
                </a>
              </address>
            </div>
            <div className="contact-form">
              <form method="post" onSubmit={handleSubmit}>
                <div className="input-group name animate-me">
                  <label htmlFor="name">name *</label>
                  <input
                    value={nameValue}
                    type="text"
                    id="name"
                    placeholder="Your Name"
                    name="name"
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                  />
                  {nameError && (
                    <p className="error-text">Name cannot be empty</p>
                  )}
                </div>
                <div className="input-group email animate-me">
                  <label htmlFor="email">email *</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your email"
                    onChange={emailChangeHandler}
                    value={emailValue}
                    onBlur={emailBlurHandler}
                  />
                  {emailError && (
                    <p className="error-text">Enter a valid email!</p>
                  )}
                </div>
                <div className="input-group msg animate-me">
                  <label htmlFor="message">message *</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Write your message"
                    cols={30}
                    rows={10}
                    value={messageValue}
                    onChange={messageChangeHandler}
                    onBlur={messageBlurHandler}
                  />
                  {messageError && (
                    <p className="error-text">Message cannot be empty</p>
                  )}
                </div>
                <div className="input-group submit animate-me">
                  <button type="submit" disabled={!formIsValid}>
                    Send here
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-content">
            <div className="copyright-text">
              <p>
                © <span className="update-year" /> website by{" "}
                <a href="https://i-gerald.com/" target="_blank">
                  iGerald
                </a>{" "}
              </p>
            </div>
            <ul className="social-icon">
              <li className="facebook">
                <a href={contactData.facebook} title="Facebook" target="_blank">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="facebook-f"
                    className="svg-inline--fa fa-facebook-f fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                    />
                  </svg>
                </a>
              </li>
              <li className="instagram">
                <a
                  href={contactData.instagram}
                  title="Instagram"
                  target="_blank"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    className="svg-inline--fa fa-instagram fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </section>
  ) : (
    <section className="section footer-area">
      <div className="contact-section">
        <div className="container">
          <div className="section-title">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="envelope-open-text"
              className="svg-inline--fa fa-envelope-open-text fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M176 216h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16H176c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16zm-16 80c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16H176c-8.84 0-16 7.16-16 16v16zm96 121.13c-16.42 0-32.84-5.06-46.86-15.19L0 250.86V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V250.86L302.86 401.94c-14.02 10.12-30.44 15.19-46.86 15.19zm237.61-254.18c-8.85-6.94-17.24-13.47-29.61-22.81V96c0-26.51-21.49-48-48-48h-77.55c-3.04-2.2-5.87-4.26-9.04-6.56C312.6 29.17 279.2-.35 256 0c-23.2-.35-56.59 29.17-73.41 41.44-3.17 2.3-6 4.36-9.04 6.56H96c-26.51 0-48 21.49-48 48v44.14c-12.37 9.33-20.76 15.87-29.61 22.81A47.995 47.995 0 0 0 0 200.72v10.65l96 69.35V96h320v184.72l96-69.35v-10.65c0-14.74-6.78-28.67-18.39-37.77z"
              />
            </svg>
            <h2>get in touch</h2>
          </div>
          <div className="content-wrapper">
            <div className="address-area">
              <address>
                <span className="label address-label animate-me">
                  address :
                </span>
                <p className="address animate-me">
                  12132 Campbell Ave, Arlington, TN, 38002
                </p>
                <span className="label email-label animate-me">E-mail :</span>
                <a
                  href="https://demo.themecon.net/cdn-cgi/l/email-protection#e59c8a9097c888848c89a58288848c89cb868a88"
                  className="email animate-me"
                >
                  <span
                    className="__cf_email__"
                    data-cfemail="b5ccdac0c798d8d4dcd9f5d2d8d4dcd99bd6dad8"
                  >
                    [email&nbsp;protected]
                  </span>
                </a>
              </address>
            </div>
            <div className="contact-form">
              <form action="#">
                <div className="input-group name animate-me">
                  <label htmlFor="name">name *</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your Name"
                  />
                </div>
                <div className="input-group email animate-me">
                  <label htmlFor="email">email *</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                  />
                </div>
                <div className="input-group msg animate-me">
                  <label htmlFor="message">message *</label>
                  <textarea
                    name="message"
                    id="message"
                    cols={30}
                    rows={10}
                    placeholder="Write your message"
                    defaultValue={""}
                  />
                </div>
                <div className="input-group submit animate-me">
                  <button type="submit">send here</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-content">
            <div className="copyright-text">
              <p>
                © <span className="update-year" /> Developed By{" "}
                <a
                  href="https://themeforest.net/user/theme_builder"
                  target="_blank"
                >
                  Theme Builder.
                </a>{" "}
                All Right Reserved{" "}
              </p>
            </div>
            <ul className="social-icon">
              <li className="facebook">
                <a href="#" title="Facebook" target="_blank">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="facebook-f"
                    className="svg-inline--fa fa-facebook-f fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                    />
                  </svg>
                </a>
              </li>
              <li className="twitter">
                <a href="#" title="Twitter" target="_blank">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="twitter"
                    className="svg-inline--fa fa-twitter fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                    />
                  </svg>
                </a>
              </li>
              <li className="linkedin">
                <a href="#" title="Linkedin" target="_blank">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    className="svg-inline--fa fa-linkedin-in fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    />
                  </svg>
                </a>
              </li>
              <li className="instagram">
                <a href="#" title="Instagram" target="_blank">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    className="svg-inline--fa fa-instagram fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </section>
  );
}
