import { useState, useEffect } from "react";
import sanityClient from "../../client";
import "lightbox.js-react/dist/index.css";
import { SlideshowLightbox } from "lightbox.js-react";
import svg from "../../images/instagram-shape.png";

export default function Gallery() {
  const [instagram, setInstagram] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type== "gallery"]{
      image{
        asset->{
          _id,
          url,
        }
      }
    }`
      )
      .then((data) => setInstagram(data));
  }, []);

  return (
    <section className="section instagram-section">
      <img src={svg} alt="shape" className="insta-shape"></img>
      <div className="container">
        <div className="section-title">
          <h2 className="section-title">Gallery</h2>
        </div>
        {instagram && (
          <SlideshowLightbox className="gallery">
            {instagram &&
              instagram.map((item, index) => (
                <img
                  src={item.image.asset.url}
                  alt="instagram img"
                  key={index}
                />
              ))}
          </SlideshowLightbox>
        )}
      </div>
    </section>
  );
}
